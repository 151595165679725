
import { Component, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { NgwWowService } from 'ngx-wow';
import $ from 'jquery';
import 'magnific-popup';
// import { DateButton } from 'angular-bootstrap-datetimepicker';
// import * as _moment from 'moment';
// import {unitOfTime} from 'moment';
import { FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith, timestamp } from 'rxjs/operators';
import { FlightsService } from 'src/app/core/services/flights.service';
import { Router } from '@angular/router';
import { Flight } from 'src/app/core/models/flight';
import { FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { SlowBuffer } from 'buffer';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements AfterViewInit {
  bannerpost = [
    { img: 'assets/img/Banner-Images/06.png', title: 'Luxury Jet Flights', urltext1: 'Download App', urltext2: 'Learn More' },
    { img: 'assets/img/Banner-Images/04.png', title: 'Luxury Jet Flights', urltext1: 'Download App', urltext2: 'Learn More' },
    { img: 'assets/img/Banner-Images/05.png', title: 'Luxury Jet Flights', urltext1: 'Download App', urltext2: 'Learn More' },
  ];
  // Apartments
  apartmentspost = [
    { img: 'assets/img/room-suite/01.jpg', title: 'Plex Apartment', text: '1 Person, Queed Bed, 2 Windows', price: '345', period: 'Month' },
    { img: 'assets/img/room-suite/02.jpg', title: 'Luxury Apartment', text: '1 Person, Queed Bed, 2 Windows', price: '345', period: 'Month' },
    { img: 'assets/img/room-suite/03.jpg', title: 'Garden Apartment', text: '1 Person, Queed Bed, 2 Windows', price: '345', period: 'Month' },
    { img: 'assets/img/room-suite/04.jpg', title: 'Duplex Apartment', text: '1 Person, Queed Bed, 2 Windows', price: '345', period: 'Month' },
  ];
  // Condos
  condospost = [
    { img: 'assets/img/condos/menu-gallery-1.jpg', title: 'City Hotel', text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do', price: '300', btntext: 'Book now' },
    { img: 'assets/img/condos/menu-gallery-2.jpg', title: 'City Hotel', text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do', price: '300', btntext: 'Book now' },
    { img: 'assets/img/condos/menu-gallery-3.jpg', title: 'City Hotel', text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do', price: '300', btntext: 'Book now' },
    { img: 'assets/img/condos/menu-gallery-4.jpg', title: 'City Hotel', text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do', price: '300', btntext: 'Book now' },
  ];
  // Counter
  counterpost = [
    { icon: 'assets/img/icon/01.png', number: '84', prefix: 'k', title: 'Apartmentss in Album' },
    { icon: 'assets/img/icon/02.png', number: '10', prefix: 'M', title: 'Active Backers Around World' },
    { icon: 'assets/img/icon/03.png', number: '02', prefix: 'k', title: 'Categories Served' },
    { icon: 'assets/img/icon/04.png', number: '100', prefix: 'M', title: 'Ideas Raised Funds' },
  ];
  // Video
  videobg = 'assets/img/About-Us/plane.png';
  // Team
  teampost = [
    { img: 'assets/img/team/01.jpg', name: 'Rosa D.William', post: 'Founder & CEO' },
    { img: 'assets/img/team/02.jpg', name: 'hilixer b. browni', post: 'co-founder' },
    { img: 'assets/img/team/03.jpg', name: 'pokoloko k. kilix', post: 'consultant' },
    { img: 'assets/img/team/04.jpg', name: 'pokoloko k. kilix', post: 'consultant' },
    { img: 'assets/img/team/05.jpg', name: 'pokoloko k. kilix', post: 'consultant' },
    { img: 'assets/img/team/01.jpg', name: 'Rosa D.William', post: 'Founder & CEO' },
    { img: 'assets/img/team/02.jpg', name: 'hilixer b. browni', post: 'co-founder' },
    { img: 'assets/img/team/03.jpg', name: 'pokoloko k. kilix', post: 'consultant' },
    { img: 'assets/img/team/04.jpg', name: 'pokoloko k. kilix', post: 'consultant' },
    { img: 'assets/img/team/05.jpg', name: 'pokoloko k. kilix', post: 'consultant' },
  ];
  bannerposts = [
    { img: 'assets/img/Banner-Images/01.png', title: "You want to travel fast and comfortably in a private jet? Worldwide and at competitive prices? You can now book a private aircraft via FLYUS VIP!" },
    { img: 'assets/img/Banner-Images/02.png', title: "Flying private defeats that roadblock and lets you decide when to depart" },
    { img: 'assets/img/Banner-Images/02.png', title: "We help you promoting your private jet" }
  ];
  isSubmitted: boolean = false
  searchFilters = new FormGroup({
    from: new FormControl('', Validators.required),
    to: new FormControl('', Validators.required),
    departureDate: new FormControl('', [Validators.required]),
    departureTime: new FormControl('', Validators.required),
    numberOfTravellers: new FormControl(1, Validators.required)
  })
  date = new Date
  minValue: Date;
  maxValue: Date;
  defaultValue: Date;


  fromAirportsList: any[] = []
  toAirportsList: any[] = []
  fromAirportObs: Observable<any>;
  toAirportObs: Observable<any>;

  ngOnInit() {
    this.loadCities()
  }

  constructor(private wowService: NgwWowService, private flightService: FlightsService, private router: Router, public datepipe: DatePipe,) {
    this.wowService.init();
  }
  // Banner


  desc = this.bannerposts[0].title
  bannerConfig = {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "fade": true,
    "infinite": true,
    "autoplay": true,
    "autoplaySpeed": 4000,
    "arrows": false,
    "dots": false,
  }

  teamConfig = {
    infinite: true,
    autoplay: false,
    arrows: false,
    dots: false,
    slidesToShow: 5,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }
  _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    let list = this.fromAirportsList.filter(option => option.cityName.toLowerCase().includes(filterValue));

    return list;
  }
  _filter2(value: string): string[] {
    const filterValue2 = value.toLowerCase();
    let list = this.toAirportsList.filter(option => option.cityName.toLowerCase().includes(filterValue2));


    return list;
  }
  ngAfterViewInit(): void {
    // Counter
    ($('.counter') as any).each(function () {
      ($(this) as any).prop('Counter', 0).animate({
        Counter: $(this).text()
      }, {
        duration: 2000,
        easing: 'swing',
        step: function () {
          ($(this) as any).text(Math.ceil(this.Counter));
        },
      });
    });
    // Video popup
    ($('.popup-video') as any).magnificPopup({
      type: 'iframe',
    });
  }


  selectEvent(item) {
    // do something with selected item
  }

  onChangeSearch(search: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }

  onFocused(e) {
    // do something
  }

  timeChangeHandler(data) {
    // console.log('time changed to', data);
  }

  invalidInputHandler() {
    // console.log('invalid input');
  }

  changeMaxValue() {
    const maxValue = new Date();
    maxValue.setHours(20);
    maxValue.setMinutes(10);
    this.maxValue = maxValue;
  }
  loadCities() {
    this.flightService.getAirports().subscribe(data => {
      this.fromAirportsList = data['data'].data
      this.toAirportsList = data['data'].data
      this.fromAirportObs = this.from.valueChanges.pipe(startWith(''), map(value => this._filter(value)));
      this.toAirportObs = this.to.valueChanges.pipe(startWith(''), map(value => this._filter2(value)));
    })
  }


  search() {
    this.isSubmitted = true
    if (this.searchFilters.invalid) {
      return;
    }
    let fullDate = new Date(this.departureDate.value)
    let time = this.departureTime.value.toString().split(':')
    let hours = time[0]

    let min = time[1]
    fullDate.setHours(hours, min)

    this.router.navigate(['flights'],
      {
        queryParams: {
          from: this.fromAirportsList.filter(e => e.cityName == this.from.value)[0].id,
          to: this.toAirportsList.filter(e => e.cityName == this.to.value)[0].id,
          numberOfTravellers: this.numberOfTravellers.value, departureDate: this.datepipe.transform(fullDate, 'dd-MM-yyyy HH:mm:ss')
        }
      });
  }

  get from() {
    return this.searchFilters.get('from') as FormControl;
  }
  get to() {
    return this.searchFilters.get('to') as FormControl;
  }

  get departureDate() {
    return this.searchFilters.get('departureDate') as FormControl;
  }
  get departureTime() {
    return this.searchFilters.get('departureTime') as FormControl;
  }
  get numberOfTravellers() {
    return this.searchFilters.get('numberOfTravellers') as FormControl;
  }
  afterChange(e) {
    // console.log(e.currentSlide);

    this.desc = this.bannerposts[e.currentSlide].title

  }

  selectChanged(event) {
    // console.log('event', event);
  }
  onSelectionChange(event) {
    if (event.option.value == this.from.value) {
      this.to.setValue('')
    }

  }
  gotoDownload() {

    document.getElementById("downloadapp").scrollIntoView({ behavior: 'smooth' });
  }
}
