<section class="pt-115 pb-115 bg-white">
    <div class="container">
        <div class="section-title text-center mb-30">

            <h2>Popular Destinations</h2>
        </div>
        <!-- <div class="col-lg-6 col-md-4 col-sm-5 d-none d-sm-block">
            <div class="latest-post-arrow arrow-style text-center">
                <div class="slide"(click)="slideright()"><i class="fal fa-arrow-left"></i></div>
                <div class="slide"(click)="slideleft()"><i class="fal fa-arrow-right"></i></div>
            </div>
        </div> -->
        <div class="row">
    
            <div class="col-lg-3 col-md-6" *ngFor='let item of apartmentspost'>
                <div class="apartment-box">
                    <div class="image-box">
                        <a (click)="navigateToFlightDetails(item.id)" class="d-block">
                            <img src="{{item.toAirport.image }}" alt="img" style="height: 160px;">
                        </a>
                    </div>
                    <div class="content-box-2 ">
                        <div class="row justify-content-around mt-2">
                            <span class="Roboto"> {{item?.fromAirport?.cityName}} </span>
                            <span> <img class="m-1" src="assets/img/Popular-destinations/Color-Plane-Icon.png" alt=""></span>
                            <span class="Roboto"> {{item?.toAirport?.cityName}}</span>
                        </div> 
                        <p>{{item?.toAirport?.departureDate}}</p>
                        <span class="sf mt-2" style="color: #0B256E ; "> Starts from ${{item?.startingFrom}}</span>
                    </div>
                </div> 
            </div>
        </div>
       
    </div>
</section>