<app-backtotop></app-backtotop>
<footer>
    <div class="footer-subscibe-area pt-120 pb-120">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-8">
                    <div class="subscribe-text text-center">
                        <div class="footer-logo mb-45">
                            <img src="{{ logo }}" alt="images">
                        </div>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed doing
                            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                            quis nostrud exercitation ullamco laboris nisi.
                        </p>
                        <form action="#" class="subscribe-form mt-50">
                            <input type="email" placeholder="Enter your email address">
                            <button type="submit">subscribe</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="copyright-area pt-20 pb-20">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-5 order-2 order-md-1">
                    <p class="copyright-text">copyright by@slidesigma</p>
                </div>
                <div class="col-md-7 order-1 order-md-2">
                    <div class="social-links">
                        <a routerLink="#"><i class="fab fa-facebook-f"></i></a>
                        <a routerLink="#"><i class="fab fa-twitter"></i></a>
                        <a routerLink="#"><i class="fab fa-behance"></i></a>
                        <a routerLink="#"><i class="fab fa-linkedin"></i></a>
                        <a routerLink="#"><i class="fab fa-youtube"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>