<section class="about-section pt-115 pb-115">
    <div class="container">
        <div class="row align-items-center justify-content-center">
            <div class="col-lg-6 col-md-10 fadeInLeft wow" data-wow-delay=".3s">
                <div class="row about-features-boxes fetaure-masonary">
                    <div class="col-sm-6">
                        <div class="single-feature-box">
                            <div class="icon">
                                <img width="70px" height="72px" src="assets/img/Flights-Type/Private-Icon.png" alt="">
                            </div>
                            <h4><a routerLink="#" class="hover">Private Jet</a></h4>
                            <p class="sf">It gives you the total control of your flight. Unbeatable level of service at
                                competitive rates.</p>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="single-feature-box only-bg mt-30" style="background-image: url({{singlefeature}});">
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="single-feature-box only-bg mt-30"
                            style="background-image: url({{singlefeature1}});">
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="single-feature-box dark mt-30">
                            <div class="icon">
                                <i class="flaticon-team" style="color: #3AA7DE;"></i>

                            </div>
                            <h4><a #SJ routerLink="#">Shared Jet</a></h4>
                            <p class="sf">
                                It is the most affordable option when you don`t need the entire aircraft for yourself.
                            </p>
                        </div>
                    </div>
                </div>
            </div>


            <div class="col-lg-6 col-md-8 col-sm-10 wow fadeInRight" data-wow-delay=".3s">
                <div class="abour-text pl-50 pr-50">
                    <div class="section-title mb-30">
                        <h2 class="Roboto">Discover Our Jets.</h2>
                    </div>
                    <p class="sf">
                        Our goal is to make the convenience and comfort of private flying more affordable and efficient
                        through sharing. <br>
                        FlyUsVIP is the most affordable way to fly on a private jet. Enjoy the benefits of flying
                        on-board a luxurious private aircraft with others, paying a fraction of the cost of sharing a
                        jet. <br>
                        <!-- Book per seat. Fly private for the same price as a business class ticket. -->
                    </p>
                </div>
            </div>


        </div>
    </div>
    <div class="about-right-bottom">
        <div class="about-bottom-img">
            <img src="{{bottomimg}}" alt="">
        </div>
    </div>
</section>