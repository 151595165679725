import { Component, OnInit } from '@angular/core';
// import { gsap } from 'gsap';
// import { MotionPathPlugin } from 'gsap/MotionPathPlugin';

// gsap.registerPlugin(MotionPathPlugin);


@Component({
  selector: 'app-download-the-app',
  templateUrl: './download-the-app.component.html',
  styleUrls: ['./download-the-app.component.css']
})
export class DownloadTheAppComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

    // gsap.to(".plane", {
      // duration: 20,
      // repeat: 12,
      // repeatDelay: 0,
      // ease: "none",
      // motionPath: {
        // path: "#mask-path",
        // align: "#mask-path",
        // autoRotate: 25,
        // alignOrigin: [0.5, 0.49]
      // }
    // });

    //const tl = gsap.timeline();
// 
    // gsap.timeline().fromTo(
      // "#mask-path",
      // { drawSVG: "0%" },
      // { drawSVG: "100%", duration: 20, delay: 0.12, ease: "none" }
    // );

  }

}
