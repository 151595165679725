import { Component, AfterViewInit } from '@angular/core';
import { NgwWowService } from 'ngx-wow';
import * as mapboxgl from 'mapbox-gl';
import $ from 'jquery';
import 'magnific-popup';

@Component({
  selector: 'app-homethree',
  templateUrl: './homethree.component.html',
  styleUrls: ['./homethree.component.css']
})
export class HomethreeComponent implements AfterViewInit {
  constructor(private wowService: NgwWowService) {
    this.wowService.init();
  }
  // Banner
  bannerpost = [
    { img: 'assets/img/banner/05.jpg', tag: 'The ultimate luxury experience', title: 'The Perfect Home For You', urltext1: 'take a tour', urltext2: 'Learn More' },
    { img: 'assets/img/banner/06.jpg', tag: 'The ultimate luxury experience', title: 'The Perfect Home For You', urltext1: 'take a tour', urltext2: 'Learn More' },
  ];
  bannerConfig = {
    "infinite": true,
    "autoplay": true,
    "autoplaySpeed": 5000,
    "dots": false,
    "fade": true,
    "arrows": false,
  }
  // Hand Picked Apartments
  handpickapartmentpost = [
    { img: 'assets/img/room-slider/06.jpg', title: 'Minimal Duplex Apartment', text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore.', price: '345', period: 'Month' },
    { img: 'assets/img/room-slider/08.jpg', title: 'Minimal Duplex Apartment', text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore.', price: '345', period: 'Month' },
    { img: 'assets/img/room-slider/07.jpg', title: 'Minimal Duplex Apartment', text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore.', price: '345', period: 'Month' },
  ];
  handpickapartmentSlider = {
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: false,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 4000,
    arrows: true,
    dots: false,
    centerMode: true,
    centerPadding: '28%',
    prevArrow:
      '<div class="slick-arrow prev-arrow"><i class="fal fa-arrow-left"></i></div>',
    nextArrow:
      '<div class="slick-arrow next-arrow"><i class="fal fa-arrow-right"></i></div>',
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          centerPadding: '20%',
        },
      },
      {
        breakpoint: 992,
        settings: {
          centerPadding: '15%',
        },
      },
      {
        breakpoint: 768,
        settings: {
          centerPadding: '10%',
        },
      },
      {
        breakpoint: 576,
        settings: {
          centerPadding: '5%',
        },
      },
    ],
  }
  // Counter
  counterpost = [
    { icon: 'assets/img/icon/01.png', number: '84', prefix: 'k', title: 'Apartmentss in Album' },
    { icon: 'assets/img/icon/02.png', number: '10', prefix: 'M', title: 'Active Backers Around World' },
    { icon: 'assets/img/icon/03.png', number: '02', prefix: 'k', title: 'Categories Served' },
    { icon: 'assets/img/icon/04.png', number: '100', prefix: 'M', title: 'Ideas Raised Funds' },
  ];
  // Video
  videobg = 'assets/img/text-block/03.jpg';
  // Gallery
  shape = 'assets/img/icon/06.png';
  gallerypost = [
    { img: 'assets/img/menu/menu-gallery-1.jpg' },
    { img: 'assets/img/menu/menu-gallery-2.jpg' },
    { img: 'assets/img/menu/menu-gallery-3.jpg' },
    { img: 'assets/img/menu/menu-gallery-1.jpg' },
    { img: 'assets/img/menu/menu-gallery-2.jpg' },
    { img: 'assets/img/menu/menu-gallery-3.jpg' },
  ];
  gallerysliderConfig = {
    slidesToShow: 3,
    slidesToScroll: 1,
    fade: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: false,
    dots: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  // Team
  teampost = [
    { img: 'assets/img/team/01.jpg', name: 'Rosa D.William', post: 'Founder & CEO' },
    { img: 'assets/img/team/02.jpg', name: 'hilixer b. browni', post: 'co-founder' },
    { img: 'assets/img/team/03.jpg', name: 'pokoloko k. kilix', post: 'consultant' },
  ];
  // cta-features
  ctafeatures = [
    {number:'01',icon:'flaticon-air-freight',animation:'.3s',title:'Easy & Free Transport',text:'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'},
    {number:'02',icon:'flaticon-fast-food',animation:'.4s',title:'Clean & Fresh Food',text:'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'},
    {number:'03',icon:'flaticon-swimming',animation:'.5s',title:'Play Ground & Swiming Pool',text:'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'},
  ];
  // Blog
  blogpost = [
    {img:'assets/img/latest-post/01.jpg',title:'Each of our 8 double rooms has its own distinct.',postdate:'28th Aug 2020',author:'Admin',text:'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore.'},
    {img:'assets/img/latest-post/02.jpg',title:'Each of our 8 double rooms has its own distinct.',postdate:'28th Aug 2020',author:'Admin',text:'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore.'},
    {img:'assets/img/latest-post/03.jpg',title:'Each of our 8 double rooms has its own distinct.',postdate:'28th Aug 2020',author:'Admin',text:'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore.'},
  ];
  // Map
  map: mapboxgl.Map;
  style = 'mapbox://styles/mapbox/light-v10';
  antialias: true;
  pitch: 45;
  ngAfterViewInit(): void {
    // Counter
    ($('.counter') as any).each(function () {
      ($(this) as any).prop('Counter', 0).animate({
        Counter: $(this).text()
      }, {
        duration: 2000,
        easing: 'swing',
        step: function () {
          ($(this) as any).text(Math.ceil(this.Counter));
        },
      });
    });
    // Video popup
    ($('.popup-video') as any).magnificPopup({
      type: 'iframe',
    });
    // Map layout
    // Map
    (mapboxgl as any).accessToken = 'pk.eyJ1IjoiYWJlZHNoIiwiYSI6ImNrNnRyZ3d4aDAyMzkzZXBoc3RsYnM0aGwifQ.yhr3W_OOI6xXElmSY8cyPg';
    this.map = new mapboxgl.Map({
      container: 'map',
      style: this.style,
      zoom: 12,
      pitch: 45,
      antialias: true,
      center: [-77.04, 38.860]
    });
    // Add map controls

    var marker = new mapboxgl.Marker()
      .setLngLat([-77.04, 38.907])
      .addTo(this.map);
    this.map.on('load', function () {
      // Insert the layer beneath any symbol layer.
      var layers = this.map.getStyle().layers;

      var labelLayerId;
      for (var i = 0; i < layers.length; i++) {
        if (layers[i].type === 'symbol' && layers[i].layout['text-field']) {
          labelLayerId = layers[i].id;
          break;
        }
      }
      this.map.addLayer({
        'id': '3d-buildings',
        'source': 'composite',
        'source-layer': 'building',
        'filter': ['==', 'extrude', 'true'],
        'type': 'fill-extrusion',
        'minzoom': 15,
        'paint': {
          'fill-extrusion-color': '#aaa',

          // use an 'interpolate' expression to add a smooth transition effect to the
          // buildings as the user zooms in
          'fill-extrusion-height': [
            'interpolate',
            ['linear'],
            ['zoom'],
            15,
            0,
            15.05,
            ['get', 'height']
          ],
          'fill-extrusion-base': [
            'interpolate',
            ['linear'],
            ['zoom'],
            15,
            0,
            15.05,
            ['get', 'min_height']
          ],
          'fill-extrusion-opacity': 0.6
        }
      },
        labelLayerId
      );
    });

  }

}
