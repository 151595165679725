import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
    providedIn: "root",
})
export class LanguageService {
    constructor(private translate: TranslateService) {}

    setLang(lang) {
        localStorage.setItem("lang", lang);
        this.translate.use(lang);
    }

    getLanguage() {
        let langToken = localStorage.getItem("lang");
        if (!langToken) {
            localStorage.setItem("lang", "en");
            this.translate.use("en");
            return "en";
        }

        this.translate.use(langToken);
        return langToken;
    }

    isRtl() {
        let langToken = localStorage.getItem("lang");
        if (!langToken) return false;

        if (langToken == "ar") {
            return true;
        } else {
            return false;
        }
    }
    getLanguageValue(): any {
        var value = localStorage.getItem("lang");
        if (value == "ar") {
            return "عربي";
        } else {
            return "English";
        }
    }
}
