<app-headerthree></app-headerthree>
<app-breadcrumb></app-breadcrumb>
<section class="offers-area pt-30 pb-60">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 offer-boxes-loop">
                <div *ngIf="flight" class="offer-box">
                    <div class="thumb">
                        <img height="464px" src="{{flight.image}}" alt="">
                    </div>
                    <div class="offer-desc pb-15">

                        <!-- <div class="Roboto d-flex justify-content-between">
                        <div>
                            {{flight.departureAirport?.code |uppercase}}
                            <br>
                            {{flight.departureAirport?.cityName}}
                            <br>
                            {{flight?.departureTime}}
                        </div>
                        <div style="
                            margin: auto;
                        "><img src="assets/img/room/MaskGroup2.png" alt=""></div>
                        <div>
                            {{flight.arrivalAirport?.code |uppercase}}
                            <br>
                            {{flight.arrivalAirport?.cityName}}

                            <br>
                            {{flight?.arrivalTime}}
                        </div>
                    </div>
                    <div class="Roboto d-flex justify-content-between my-5">
                        <div style="
                        margin-top: 25px;
                    ">
                            <img src="assets/img/room/c1.png" alt="">
                            {{flight?.departureDate}}
                        </div>

                        <div style="display: grid;">
                            <span class=" text-right" style="font-size: 10px;"> Starting From</span>
                            <span style="color: #0B256E;"> ${{flight.price}}/Seat</span>

                        </div>
                    </div> -->



                        <div class="row Roboto d-flex justify-content-between my-5">
                            <span> {{flight.airline}}</span>
                            <div style="display: grid;">

                                <span class=" text-right" style="font-size: 10px;"> Starting From</span>
                                <span style="color: #0B256E;"> ${{flight.price}}/Seat</span>
                            </div>


                        </div>
                        <div class="row  Roboto d-flex justify-content-between my-5">
                            <span> {{flight.departureAirport?.name}}</span>
                            <span style="color: #0B256E;">{{flight.remainingSeats}} Seats Left</span>

                        </div>


                    </div>
                    <div class=" offer-desc pb-15 mt-25 ">
                        <h5 class="pb-25">About Jet</h5>
                        <div class="about" [class.show]="show">
                            {{flight.jet?.description}}
                        </div>
                        <a (click)="show = !show">{{ show ? 'See Less': 'See More'}} </a>
                    </div>
                    <div class="room-fearures clearfix offer-desc pb-15 mt-25">
                        <h5 class="pb-25">Amenities</h5>
                        <ul class="room-fearures-list" *ngFor="let item of aminities">
                            <li><img src="{{item.icon}}" alt=""> {{item.name}}</li>

                        </ul>
                    </div>

                    <div class=" offer-desc pb-15 mt-25 pl-45 pr-0">
                        <h5 class="pb-25">Aircraft Details</h5>
                        <div class="container">
                            <div class="row">
                                <div class="col-md-3 col-sm-4 col-lg-3 header">
                                    <span>Manufacture</span>
                                    <br>
                                    {{flight.jet?.seats}}

                                </div>
                                <div class="col-md-3 col-sm-4 col-lg-3 header">
                                    <span> Max Speed</span>
                                    <br>
                                    {{flight.jet?.seats}}
                                </div>
                                <div class="col-md-3 col-sm-4 col-lg-3 header">
                                    <span> Build</span>
                                    <br>
                                    {{flight.jet?.seats}}
                                </div>
                                <div class="col-md-3 col-sm-4 col-lg-3 header">
                                    <span> Cabin Height</span>
                                    <br>
                                    {{flight.jet?.seats}}
                                </div>
                                <div class="col-md-3 col-sm-4 col-lg-3 header">
                                    <span>Luggage</span>
                                    <br>
                                    {{flight.jet?.seats}}
                                </div>
                                <div class="col-md-3 col-sm-4 col-lg-3 header">
                                    <span>Seats</span>
                                    <br>
                                    {{flight.jet?.seats}}
                                </div>

                                <div class="col-md-3 col-sm-4 col-lg-3 header">
                                    <span> Pilots</span>
                                    <br>
                                    {{flight.jet?.seats}}
                                </div>
                                <div class="col-md-3 col-sm-4 col-lg-3 header">
                                    <span> Refurbished</span>
                                    <br>
                                    {{flight.jet?.seats}}
                                </div>
                            </div>


                        </div>
                    </div>






                    <div class="offer-desc pb-15 mt-25 pl-45 px-0" style="background-color: transparent !important;">
                        <h5 class="pb-25  pl-60">Aircraft Images</h5>
                        <div>
                            <img class="m-1" *ngFor="let img of flight.jet?.images" src="{{img?.name}}" height="194px"
                                width="208">

                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 position-sticky">
                <div class="room-booking-form">
                    <!-- <h5 class="title">Check Availability</h5> -->
                    <form [formGroup]="searchFilters">
                        <div class="input-group input-group-two left-icon mb-20">
                            <label for="arrival-date">Where from?</label>

                            <div class="icon"> <img height="17px" width="17px"
                                    src="assets/img/Check-Availability-Icons/from.png"></div>
                            <input type="text" placeholder="Select Country" aria-label="Number" matInput
                                [formControl]="from" [matAutocomplete]="auto"
                                [ngClass]="(from.touched || isSubmitted) && from.errors?.required? 'form-control-error':'ng-pristine ng-invalid ng-touched'">
                            <mat-autocomplete id="from" autoActiveFirstOption #auto="matAutocomplete">
                                <mat-option *ngFor="let option of fromAirportObs | async" [value]="option?.cityName">
                                    {{option?.cityName}}
                                </mat-option>
                            </mat-autocomplete>
                        </div>

                        <div class="input-group input-group-two left-icon mb-20">
                            <label for="departure-date">Where to?</label>
                            <div class="icon"> <img height="17px" width="17px"
                                    src="assets/img/Check-Availability-Icons/dist.png"></div>
                            <input type="text" placeholder="Select Country" aria-label="Number" matInput
                                formControlName="to" [matAutocomplete]="to1"
                                [ngClass]="(to.touched || isSubmitted) && to.errors?.required? 'form-control-error':'ng-pristine ng-invalid ng-touched'">
                            <mat-autocomplete id="to" autoActiveFirstOption #to1="matAutocomplete">
                                <mat-option *ngFor="let option of toAirportObs | async" [value]="option?.cityName">
                                    {{option?.cityName}}
                                </mat-option>
                            </mat-autocomplete>
                        </div>
                        <div class="input-group input-group-two left-icon mb-20">
                            <label for="guests">Select date</label>
                            <a class="icon" (click)="picker.open()"><img height="17px" width="17px"
                                    src="assets/img/Check-Availability-Icons/calender.png">
                            </a>
                            <input matInput [matDatepicker]="picker" placeholder="dd/mm/yyyy"
                                [ngClass]="(departureDate.touched || isSubmitted) && departureDate.errors?.required? 'form-control-error':'ng-pristine ng-invalid ng-touched'"
                                formControlName="departureDate">
                            <mat-datepicker #picker></mat-datepicker>
                        </div>
                        <div class="input-group input-group-two left-icon mb-20">
                            <label for="guests">Select time</label>
                            <a (click)="picker2.open()" class="icon">
                                <img height="17px" width="17px" src="assets/img/Check-Availability-Icons/clock.png">
                            </a>
                            <input [ngxTimepicker]="picker2" placeholder="HH:MM" formControlName="departureTime"
                                [format]="24"
                                [ngClass]="(departureTime.touched || isSubmitted) && departureTime.errors?.required?  'form-control-error':'ng-pristine ng-invalid ng-touched'">
                            <ngx-material-timepicker #picker2></ngx-material-timepicker>


                        </div>
                        <div class="input-group input-group-two left-icon mb-20">
                            <label for="guests">Number of travellers</label>
                            <div class="icon"> <img height="17px" width="17px"
                                    src="assets/img/Check-Availability-Icons/member.png"></div>
                            <input type="number" placeholder="Number of travellers" formControlName="numberOfTravellers"
                                name="numberOfTravellers" min="0"
                                [ngClass]="(numberOfTravellers.touched || isSubmitted) && numberOfTravellers.errors?.required?  'form-control-error':'ng-pristine ng-invalid ng-touched'">

                        </div>

                        <div class="input-group">
                            <button class="test" (click)="search()">check availability</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>