<app-headerthree></app-headerthree>
<app-breadcrumb></app-breadcrumb>
<section class="contact-part pt-115 pb-115">
    <div class="section-title text-center mb-50">
        <h2>Contact Us</h2>
    </div>
    <div class="container">
        <!-- Contact Info -->

        <!-- Contact Form -->
        <div class="contact-form">
            <form [formGroup]="contactUs">
                <div class="row">
                    <div class="col-md-4">
                        <div class="input-group mb-30">
                            <span class="icon"><i class="far fa-user"></i></span>
                            <input type="text" placeholder="Your full name" formControlName="FullName">
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="input-group mb-30">
                            <span class="icon"><i class="far fa-phone"></i></span>
                            <input type="text" placeholder="Add phone number" formControlName="MobileNumber">

                        </div><span *ngIf="MobileNumber.errors?.pattern" class="danger">
                            phone number is not valid
                        </span>
                    </div>
                    <div class="col-md-4">
                        <div class="input-group mb-30">
                            <span class="icon"><i class="far fa-book"></i></span>
                            <input type="text" placeholder="Select Subject" formControlName="Subject">
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="input-group textarea mb-30">
                            <span class="icon"><i class="far fa-pen"></i></span>
                            <textarea placeholder="Enter messages" formControlName="Message"></textarea>
                        </div>
                    </div>
                    <div class="col-12 text-center">
                        <button type="submit" class="test sf" (click)="send()">Send</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>