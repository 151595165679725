import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FlightsService } from 'src/app/core/services/flights.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-blogpost',
  templateUrl: './blogpost.component.html',
  styleUrls: ['./blogpost.component.css']
})
export class BlogpostComponent implements OnInit {
  startingitem=0
  enditem=4
  constructor(private flightService:FlightsService,private router:Router) { }
   // Blog
   apartmentspost = [
    
  ];
  
  blogConfig = {
    "slidesToShow": 4,
    "slidesToScroll": 1,
    "fade": false,
    "infinite": true,
    "autoplay": true,
    "autoplaySpeed": 4000,
    "arrows": true,
    "dots": false,
    "prevArrow": '.latest-post-arrow .prev-arrow',
    "nextArrow": '.latest-post-arrow .next-arrow',
    "responsive": [
      {
        "breakpoint": 992,
        "settings": {
          "slidesToShow": 2,
        },
      },
      {
        "breakpoint": 576,
        "settings": {
          "slidesToShow": 1,
        },
      },
    ],
  }

  ngOnInit(): void {
    this.getPopularDistination()
  }
  
  getPopularDistination(){
    this.flightService.getroutes().subscribe(
      data=>{
        this.apartmentspost=data['data'].data
        this.apartmentspost.forEach(
          e=>{
          
            
            e.toAirport.image=this.getFile(e.toAirport.image)
          }
        )
      }
    )
  }
  getFile(name) {
    let pictureString = environment.baseUrl + 'file/' + name;
    return pictureString;
  }
  navigateToFlightDetails(id){
    this.router.navigate( ['flight-details'], { queryParams: { id: id}});
  }
  // slideright(){
  //   if(this.enditem<this.apartmentspost.length)
  //  { this.startingitem=this.startingitem+1
  //   this.enditem=this.enditem+1
  //   this.apartmentspostview=this.apartmentspost.slice(this.startingitem,this.enditem)}
    
  // }
  // slideleft(){
  //   if(this.startingitem>0)
  //   {this.startingitem=this.startingitem-1
  //   this.enditem=this.enditem-1
  //   this.apartmentspostview=this.apartmentspost.slice(this.startingitem,this.enditem)}
  // }
}
