<app-backtotop></app-backtotop>
<footer class="footer-two">

    <div class="footer-widget-area pt-50">
        <div class="container">

            <div class="row d-flex justify-content-between">

                <div class="col-lg-3 col-sm-6 order-1 order-lg-2">
                    <!-- Nav Widget -->


                    <ul class="d-flex  pt-50" style="justify-content: space-evenly;">
                        <li class="footer-links"><a class="text-white " routerLink="#">Terms</a></li>
                        <li class="footer-links"><a class="text-white " routerLink="#">Privacy</a></li>
                        <li class="footer-links"><a class="text-white " routerLink="/ContactUs">Contact</a></li>

                    </ul>


                </div>
                <div class="col-lg-3 col-sm-6 order-2  pt-50">
                    <!-- Site Info Widget -->
                    <div class="widget site-info-widget text-center ">
                        <div class="footer-logo mb-4   ">
                            <img src="{{ logo }}" alt="Logo">
                        </div>
                        <p style="color: #FFFFFF; opacity: 0.3; ">
                            © 2021 FlyUsVip. All rights reserved.
                            <br>
                            Made With Love By <a style="opacity: 1 !important;" target="_blank"
                                href="https://www.technzone.com/"> Technzone</a>

                        </p>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 order-3 pt-50">
                    <!-- Site Info Widget -->
                    <div class="widget site-info-widget pl-100 ">
                        <span>Join Us</span>
                        <div class="social-links pt-20">
                            <a routerLink="#"><i class="fab fa-facebook-f"></i></a>
                            <a routerLink="#"><i class="fab fa-instagram"></i></a>
                            <a routerLink="#"><i class="fab fa-twitter"></i></a>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</footer>