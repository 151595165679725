import { Component, OnInit } from '@angular/core';
import * as mapboxgl from 'mapbox-gl';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  // Logo
  logo = 'assets/img/logo-whire.png';

  map: mapboxgl.Map;
  style = 'mapbox://styles/mapbox/light-v10';
  antialias: true;
  pitch: 45;

  constructor() { }

  ngOnInit(): void {
   
  }
}
