<app-headertwo></app-headertwo>
<!--====== BANNER PART START ======-->
<ngx-slick-carousel class="banner-area banner-style-two" id="bannerSlider" #slickModal="slick-carousel"
  [config]="bannerConfig">
  <div ngxSlickItem class="single-banner d-flex align-items-center justify-content-center"
    *ngFor='let item of bannerpost'>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <div class="banner-content text-center">
            <span class="promo-tag" data-animation="fadeInDown" data-delay=".6s">{{item.tag}}</span>
            <h1 class="title" data-animation="fadeInLeft" data-delay=".9s">{{item.title}} </h1>
            <ul>
              <li data-animation="fadeInUp" data-delay="1.1s">
                <a class="main-btn btn-filled" routerLink="/contact">{{item.urltext1}}</a>
              </li>
              <li data-animation="fadeInUp" data-delay="1.3s">
                <a class="main-btn btn-border" routerLink="/about">{{item.urltext2}}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- banner bg -->
    <div class="banner-bg" style="background-image: url({{item.img}});"></div>
    <div class="banner-overly"></div>
  </div>
</ngx-slick-carousel>
<!--====== BANNER PART ENDS ======-->

<!--====== Book Room Style2 START ======-->

<section class="book-room-style2">
  <div class="container">
    <div class="row">
      <div class="col-xl-6">
        <div class="book-room-content">
          <h3 class="mb-15">Executive Apartments</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna..</p>
          <a routerLink='/contact'>
            <i class="fal fa-long-arrow-right"></i>
            Contact Us
          </a>
        </div>
      </div>
      <div class="col-xl-6 mobile-none">
        <div class="calender-box">
          <form>
            <ul>
              <li>
                <label>Arrival Date</label>
                <div class="calender-input-box">
                  <div class="calender-input-inner">
                    <span>Sep</span>
                    <h3>12</h3>
                    <div class="calendar-controls">
                      <a routerLink="#" class="next-day">
                        <i class="fal fa-long-arrow-up"></i>
                      </a>
                      <a routerLink="#" class="prev-day">
                        <i class="fal fa-long-arrow-down"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <label>Departure Date</label>
                <div class="calender-input-box">
                  <div class="calender-input-inner">
                    <span>Oct</span>
                    <h3>15</h3>
                    <div class="calendar-controls">
                      <a routerLink="#" class="next-day">
                        <i class="fal fa-long-arrow-up"></i>
                      </a>
                      <a routerLink="#" class="prev-day">
                        <i class="fal fa-long-arrow-down"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <label>Adults</label>
                <div class="calender-input-box">
                  <div class="calender-input-inner">
                    <h3>01</h3>
                    <div class="calendar-controls">
                      <a routerLink="#" class="next-day">
                        <i class="fal fa-long-arrow-up"></i>
                      </a>
                      <a routerLink="#" class="prev-day">
                        <i class="fal fa-long-arrow-down"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <label>Child</label>
                <div class="calender-input-box">
                  <div class="calender-input-inner">
                    <h3>02</h3>
                    <div class="calendar-controls">
                      <a routerLink="#" class="next-day">
                        <i class="fal fa-long-arrow-up"></i>
                      </a>
                      <a routerLink="#" class="prev-day">
                        <i class="fal fa-long-arrow-down"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <p class="reserve-block text-white">
              <span>Reservation is available 24 hours</span>
              <a routerLink='/contact' class="text-white">
                <i class="fal fa-long-arrow-right"></i>
                CHECK AVAILABILITY
              </a>
            </p>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>


<!--====== Book Room Style2 END ======-->

<!--======  START Hotel Intro ======-->

<section class="condos-overlay-sec pt-115 pb-155">
  <div class="container-fluid">
    <div class="section-title text-center mb-50">
      <span class="title-tag"> facilities </span>
      <h2>What We Offer</h2>
    </div>
    <div class="row">
      <div class="col-lg-3 col-md-6" *ngFor='let item of facilitiespost'>
        <div class="condo-item hotel-intro" style="background-image: url({{item.img}});">
          <div class="title">
            <div class="icon">
              <i class="{{item.icon}}"></i>
            </div>
            <h4 class="text-white">{{item.title}}</h4>
            <div class="display-on-hover">
              <p class="text-white">{{item.text}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!--======  END Hotel Intro ======-->

<!--====== BOOKING FORM START ======-->
<div class="booking-form overlapped">
  <div class="container">
    <div class="booking-form-inner">
      <form action="#">
        <div class="row align-items-end">
          <div class="col-lg-3 col-md-6">
            <div class="inputs-filed mt-30">
              <label for="arrival-date">Arrival Date</label>
              <div class="icon"><i class="fal fa-calendar-alt"></i></div>
              <input type="text" placeholder="24th march 2020" name="arrival-date" id="arrival-date">
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div class="inputs-filed mt-30">
              <label for="departure-date">Departure Date</label>
              <div class="icon"><i class="fal fa-calendar-alt"></i></div>
              <input type="text" placeholder="30th march 2020" name="departure-date" id="departure-date">
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div class="inputs-filed mt-30">
              <label for="guests">Guests</label>
              <select name="guests" id="guests" nice-select>
                <option value="" disabled selected>Select From Here</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="4">4</option>
                <option value="8">8</option>
              </select>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div class="inputs-filed mt-30">
              <button type="submit">check availability</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<!--====== BOOKING FORM END ======-->

<!--====== VIDEO WRAP START ======-->
<div class="video-wrap full-section" style="background-image: url({{videoimg}});">
  <a routerLink="http://www.youtube.com/embed/watch?v=EEJFMdfraVY" class="popup-video wow fadeInDown"
    data-wow-delay=".3s">
    <img src="{{videoicon}}" alt="">
  </a>
</div>
<!--====== VIDEO WRAP END ======-->

<!--====== ROOM TYPE START ======-->
<section class="room-type-section pt-115 pb-115" style="background-image: url(assets/img/bg/01.jpg);">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6">
        <div class="section-title text-lg-left text-center">
          <span class="title-tag">room type</span>
          <h2>Inspired Loding</h2>
        </div>
      </div>
      <div class="col-lg-6">
        <ul ngbNav #nav="ngbNav" class="room-filter nav nav-pills justify-content-center justify-content-lg-end"
          id="room-tab" role="tablist">
          <li ngbNavItem>
            <a ngbNavLink>Luxury</a>
            <ng-template ngbNavContent>
              <div class="room-items">
                <div class="row">
                  <div class="col-lg-8">
                    <div class="row">
                      <div class="col-12">
                        <div class="room-box extra-wide">
                          <div class="room-bg" style="background-image: url({{room4}});"></div>
                          <div class="room-content">
                            <span class="room-count"><i class="fal fa-th"></i>03 Apartments</span>
                            <h3><a routerLink="/apartment-details">Luxury Hall Of Fame</a></h3>
                          </div>
                          <a routerLink="/apartment-details" class="room-link"><i class="fal fa-arrow-right"></i></a>
                        </div>
                      </div>
                      <div class="col-lg-6 col-sm-6">
                        <div class="room-box">
                          <div class="room-bg" style="background-image: url({{room1}});">
                          </div>
                          <div class="room-content">
                            <span class="room-count"><i class="fal fa-th"></i>05 Apartments</span>
                            <h3><a routerLink="/apartment-details">Pendora Fame</a></h3>
                          </div>
                          <a routerLink="/apartment-details" class="room-link"><i class="fal fa-arrow-right"></i></a>
                        </div>
                      </div>
                      <div class="col-lg-6 col-sm-6">
                        <div class="room-box">
                          <div class="room-bg" style="background-image: url({{room2}});">
                          </div>
                          <div class="room-content">
                            <span class="room-count"><i class="fal fa-th"></i>10 Apartments</span>
                            <h3><a routerLink="/apartment-details">Pacific Apartment</a></h3>
                          </div>
                          <a routerLink="/apartment-details" class="room-link"><i class="fal fa-arrow-right"></i></a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="room-box extra-height">
                      <div class="room-bg" style="background-image: url({{room3}});">
                      </div>
                      <div class="room-content">
                        <span class="room-count"><i class="fal fa-th"></i>12 Apartments</span>
                        <h3><a routerLink="/apartment-details">Junior Suite</a></h3>
                      </div>
                      <a routerLink="/apartment-details" class="room-link"><i class="fal fa-arrow-right"></i></a>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </li>
          <li ngbNavItem>
            <a ngbNavLink>Family</a>
            <ng-template ngbNavContent>
              <div class="room-items">
                <div class="row">
                  <div class="col-lg-4">
                    <div class="room-box extra-height">
                      <div class="room-bg" style="background-image: url({{room3}});">
                      </div>
                      <div class="room-content">
                        <span class="room-count"><i class="fal fa-th"></i>12 Apartments</span>
                        <h3><a routerLink="/apartment-details">Junior Suite</a></h3>
                      </div>
                      <a routerLink="/apartment-details" class="room-link"><i class="fal fa-arrow-right"></i></a>
                    </div>
                  </div>
                  <div class="col-lg-8">
                    <div class="row">
                      <div class="col-lg-6 col-sm-6">
                        <div class="room-box">
                          <div class="room-bg" style="background-image: url({{room2}});">
                          </div>
                          <div class="room-content">
                            <span class="room-count"><i class="fal fa-th"></i>10 Apartments</span>
                            <h3><a routerLink="/apartment-details">Pacific Apartment</a></h3>
                          </div>
                          <a routerLink="/apartment-details" class="room-link"><i class="fal fa-arrow-right"></i></a>
                        </div>
                      </div>
                      <div class="col-lg-6 col-sm-6">
                        <div class="room-box">
                          <div class="room-bg" style="background-image: url({{room1}});">
                          </div>
                          <div class="room-content">
                            <span class="room-count"><i class="fal fa-th"></i>05 Apartments</span>
                            <h3><a routerLink="/apartment-details">Pendora Fame</a></h3>
                          </div>
                          <a routerLink="/apartment-details" class="room-link"><i class="fal fa-arrow-right"></i></a>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="room-box extra-wide">
                          <div class="room-bg" style="background-image: url({{room4}});"></div>
                          <div class="room-content">
                            <span class="room-count"><i class="fal fa-th"></i>03 Apartments</span>
                            <h3><a routerLink="/apartment-details">Luxury Hall Of Fame</a></h3>
                          </div>
                          <a routerLink="/apartment-details" class="room-link"><i class="fal fa-arrow-right"></i></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </li>
          <li ngbNavItem>
            <a ngbNavLink>Double Bed</a>
            <ng-template ngbNavContent>
              <div class="room-items">
                <div class="row">
                  <div class="col-lg-4">
                    <div class="room-box extra-height">
                      <div class="room-bg" style="background-image: url({{room3}});">
                      </div>
                      <div class="room-content">
                        <span class="room-count"><i class="fal fa-th"></i>12 Apartments</span>
                        <h3><a routerLink="/apartment-details">Junior Suite</a></h3>
                      </div>
                      <a routerLink="/apartment-details" class="room-link"><i class="fal fa-arrow-right"></i></a>
                    </div>
                  </div>
                  <div class="col-lg-8">
                    <div class="row">
                      <div class="col-12">
                        <div class="room-box extra-wide">
                          <div class="room-bg" style="background-image: url({{room4}});"></div>
                          <div class="room-content">
                            <span class="room-count"><i class="fal fa-th"></i>03 Apartments</span>
                            <h3><a routerLink="/apartment-details">Luxury Hall Of Fame</a></h3>
                          </div>
                          <a routerLink="/apartment-details" class="room-link"><i class="fal fa-arrow-right"></i></a>
                        </div>
                      </div>
                      <div class="col-lg-6 col-sm-6">
                        <div class="room-box">
                          <div class="room-bg" style="background-image: url({{room1}});">
                          </div>
                          <div class="room-content">
                            <span class="room-count"><i class="fal fa-th"></i>05 Apartments</span>
                            <h3><a routerLink="/apartment-details">Pendora Fame</a></h3>
                          </div>
                          <a routerLink="/apartment-details" class="room-link"><i class="fal fa-arrow-right"></i></a>
                        </div>
                      </div>
                      <div class="col-lg-6 col-sm-6">
                        <div class="room-box">
                          <div class="room-bg" style="background-image: url({{room2}});">
                          </div>
                          <div class="room-content">
                            <span class="room-count"><i class="fal fa-th"></i>10 Apartments</span>
                            <h3><a routerLink="/apartment-details">Pacific Apartment</a></h3>
                          </div>
                          <a routerLink="/apartment-details" class="room-link"><i class="fal fa-arrow-right"></i></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </li>
          <li ngbNavItem>
            <a ngbNavLink>Relex</a>
            <ng-template ngbNavContent>
              <div class="room-items">
                <div class="row">
                  <div class="col-lg-8">
                    <div class="row">
                      <div class="col-lg-6 col-sm-6">
                        <div class="room-box">
                          <div class="room-bg" style="background-image: url({{room1}});">
                          </div>
                          <div class="room-content">
                            <span class="room-count"><i class="fal fa-th"></i>05 Apartments</span>
                            <h3><a routerLink="/apartment-details">Pendora Fame</a></h3>
                          </div>
                          <a routerLink="/apartment-details" class="room-link"><i class="fal fa-arrow-right"></i></a>
                        </div>
                      </div>
                      <div class="col-lg-6 col-sm-6">
                        <div class="room-box">
                          <div class="room-bg" style="background-image: url({{room2}});">
                          </div>
                          <div class="room-content">
                            <span class="room-count"><i class="fal fa-th"></i>10 Apartments</span>
                            <h3><a routerLink="/apartment-details">Pacific Apartment</a></h3>
                          </div>
                          <a routerLink="/apartment-details" class="room-link"><i class="fal fa-arrow-right"></i></a>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="room-box extra-wide">
                          <div class="room-bg" style="background-image: url({{room4}});"></div>
                          <div class="room-content">
                            <span class="room-count"><i class="fal fa-th"></i>03 Apartments</span>
                            <h3><a routerLink="/apartment-details">Luxury Hall Of Fame</a></h3>
                          </div>
                          <a routerLink="/apartment-details" class="room-link"><i class="fal fa-arrow-right"></i></a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="room-box extra-height">
                      <div class="room-bg" style="background-image: url({{room3}});">
                      </div>
                      <div class="room-content">
                        <span class="room-count"><i class="fal fa-th"></i>12 Apartments</span>
                        <h3><a routerLink="/apartment-details">Junior Suite</a></h3>
                      </div>
                      <a routerLink="/apartment-details" class="room-link"><i class="fal fa-arrow-right"></i></a>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </li>
        </ul>
      </div> 
    </div>
    <div class="tab-content mt-65" id="room-tabContent">
      <div [ngbNavOutlet]="nav"></div>
    </div>
  </div>
</section>
<!--====== ROOM TYPE END ======-->

<!--====== CORE FEATURES START ======-->
<section class="core-feature-section bg-white pt-115 pb-115">
  <div class="container">
    <div class="section-title text-center mb-50">
      <span class="title-tag"> facilities </span>
      <h2>Core Features</h2>
    </div>
    <!-- Featre Loop -->
    <div class="row features-loop">
      <div class="col-lg-4 col-sm-6 order-1">
        <div class="feature-box with-hover-img wow fadeInLeft" data-wow-delay=".3s">
          <div class="icon">
            <i class="flaticon-rating"></i>
          </div>
          <h3><a routerLink="#">Have High Rating</a></h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
            tempor incididunt ut labore et dolore magna..
          </p>
          <span class="count">01</span>
          <div class="hover-img" style="background-image: url({{feature1}});"></div>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6 order-2">
        <div class="feature-box wow fadeInUp" data-wow-delay=".4s">
          <div class="icon">
            <i class="flaticon-clock"></i>
          </div>
          <h3><a routerLink="#">Quiet Hours</a></h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
            tempor incididunt ut labore et dolore magna..
          </p>
          <span class="count">02</span>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6 order-3 order-sm-4 order-lg-3">
        <div class="feature-box with-hover-img wow fadeInRight" data-wow-delay=".5s">
          <div class="icon">
            <i class="flaticon-location-pin"></i>
          </div>
          <h3><a routerLink="#">Best Locations</a></h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
            tempor incididunt ut labore et dolore magna..
          </p>
          <span class="count">03</span>
          <div class="hover-img" style="background-image: url({{feature2}});"></div>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6 order-4 order-sm-3 order-lg-4">
        <div class="feature-box wow fadeInLeft" data-wow-delay=".7s">
          <div class="icon">
            <i class="flaticon-clock-1"></i>
          </div>
          <h3><a routerLink="#">Free Cancellation</a></h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
            tempor incididunt ut labore et dolore magna..
          </p>
          <span class="count">04</span>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6 order-5">
        <div class="feature-box with-hover-img wow fadeInUp" data-wow-delay=".9s">
          <div class="icon">
            <i class="flaticon-credit-card"></i>
          </div>
          <h3><a routerLink="#">Payment Options</a></h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
            tempor incididunt ut labore et dolore magna..
          </p>
          <span class="count">05</span>
          <div class="hover-img" style="background-image: url({{feature3}});"></div>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6 order-6">
        <div class="feature-box wow fadeInDown" data-wow-delay="1.1s">
          <div class="icon">
            <i class="flaticon-discount"></i>
          </div>
          <h3><a routerLink="#">Special Offers</a></h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
            tempor incididunt ut labore et dolore magna..
          </p>
          <span class="count">06</span>
        </div>
      </div>
    </div>
  </div>
</section>
<!--====== CORE FEATURES END ======-->

<!--====== ROOM SLIDER START ======-->
<section class="room-slider">
  <div class="container-fluid p-0">
    <ngx-slick-carousel class="row rooms-slider-one" #slickModal="slick-carousel" [config]="roomslideroneConfig">
      <div ngxSlickItem class="col" *ngFor='let item of roomslideronepost'>
        <div class="slider-img" style="background-image: url({{item.img}});">
        </div>
      </div>
    </ngx-slick-carousel>
  </div>
  <div class="rooms-content-wrap">
    <div class="container">
      <div class="row justify-content-center justify-content-md-start">
        <div class="col-xl-4 col-lg-5 col-sm-8">
          <div class="room-content-box">
            <div class="slider-count"></div>
            <div class="slider-count-big"></div>
            <ngx-slick-carousel class="room-content-slider" #slickModal="slick-carousel" [config]="roomslidercontentConfig">
              <div ngxSlickItem class="single-content" *ngFor='let item of roomslidertwopost'>
                <div class="icon">
                  <i class="{{item.icon}}"></i>
                </div>
                <h3><a routerLink="#">{{item.title}}</a></h3>
                <p>{{item.text}}</p>
              </div>
            </ngx-slick-carousel>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--====== ROOM SLIDER END ======-->
<!--====== LATEST NEWS START ======-->
<app-blogpost></app-blogpost>
<!--====== LATEST NEWS END ======-->
<!--====== INSTAGRAM FEED PART START ======-->
<app-instapost></app-instapost>
<!--====== INSTAGRAM FEED PART END ======-->
<app-footertwo></app-footertwo>