import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { element } from 'protractor';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { Amenity } from 'src/app/core/models/Amenity';
import { Flight } from 'src/app/core/models/flight';
import { FlightsService } from 'src/app/core/services/flights.service';
import { environment } from 'src/environments/environment';
import * as  moment from 'moment';
@Component({
  selector: 'app-trending',
  templateUrl: './trending.component.html',
  styleUrls: ['./trending.component.css']
})
export class TrendingComponent implements OnInit {
  flightId
  fromAirportsList: any[] = []
  toAirportsList: any[] = []
  fromAirportObs: Observable<any>;
  toAirportObs: Observable<any>;
  aminities: Amenity[]=[]

  isSubmitted: boolean = false
  searchFilters = new FormGroup({
    from: new FormControl('', Validators.required),
    to: new FormControl('', Validators.required),
    departureDate: new FormControl('', [Validators.required]),
    departureTime: new FormControl('', Validators.required),
    numberOfTravellers: new FormControl(1, Validators.required)
  })
  constructor(private flightService: FlightsService, private route: ActivatedRoute, private router: Router, public datepipe: DatePipe,) { }
  show = false;
  flight: Flight = new Flight()
  // Popular Apartments
  featuresbg = 'assets/img/bg/06.jpg';

  popularapartment = [
    { img: 'assets/img/room/01.jpg', title: 'Minimal Duplex Apartment', text: 'Lorem ipsum dolor sit amet, consectetur adipi sicing elit, sed do eiusmod tempor.', price: '345', period: 'Month' },
    { img: 'assets/img/room/02.jpg', title: 'Minimal Duplex Apartment', text: 'Lorem ipsum dolor sit amet, consectetur adipi sicing elit, sed do eiusmod tempor.', price: '345', period: 'Month' },
    { img: 'assets/img/room/03.jpg', title: 'Minimal Duplex Apartment', text: 'Lorem ipsum dolor sit amet, consectetur adipi sicing elit, sed do eiusmod tempor.', price: '345', period: 'Month' },
  ];

  ngOnInit(): void {


    this.route.queryParams.subscribe(
      params => {
        this.flightId = params['id'];
        this.getFlightById(this.flightId)
      }


    )
    this.loadCities()
  }

  loadCities() {
    this.flightService.getAirports().subscribe(data => {
      this.fromAirportsList = data['data'].data
      this.toAirportsList = data['data'].data
      this.fromAirportObs = this.from.valueChanges.pipe(startWith(''), map(value => this._filter(value)));
      this.toAirportObs = this.to.valueChanges.pipe(startWith(''), map(value => this._filter2(value)));


    })

  }
  _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    let list = this.fromAirportsList.filter(option => option.cityName.toLowerCase().includes(filterValue));

    return list;
  }
  _filter2(value: string): string[] {
    const filterValue2 = value.toLowerCase();
    let list = this.toAirportsList.filter(option => option.cityName.toLowerCase().includes(filterValue2));


    return list;
  }

  getFlightById(id) {
    this.flightService.getFlightbyid(id).subscribe(
      data => {

        this.flight = <Flight>data['data']

        this.aminities = this.flight.amenities

        this.aminities.forEach(element => {

          element.icon = this.getFile(element.icon)

        });
        this.from.setValue(this.flight.departureAirport.cityName)
        this.to.setValue(this.flight.arrivalAirport.cityName)

        let date = this.flight.departureDate.split(' ')
        let depdate = date[0].split('-')
        let deptime = date[1].split(':')
        let timeString = deptime[0] + ':' + deptime[1];

        let finaldepdate = new Date()
        finaldepdate.setFullYear(depdate[2])
        finaldepdate.setMonth(depdate[1])
        finaldepdate.setDate(depdate[0])



        this.departureTime.setValue(timeString);
        this.departureDate.setValue(finaldepdate)







        this.flight.image = this.getFile(this.flight.arrivalAirport.image)

        this.flight.jet.images.forEach(element => {
          element.name = this.getFile(element.name)


        });



      }
    )
  }


  search() {
    this.isSubmitted = true
    if (this.searchFilters.invalid) {
      return;
    }
    let fullDate = new Date(this.departureDate.value)
    let time = this.departureTime.value == null ? this.departureTime.value : this.departureTime.value.toString().split(':')
    let hours = time[0]

    let min = time[1]
    fullDate.setHours(hours, min)
    this.router.navigate(['flights'],
      {
        queryParams: {
          from: this.fromAirportsList.filter(e => e.cityName == this.from.value)[0].id,
          to: this.toAirportsList.filter(e => e.cityName == this.to.value)[0].id,
          numberOfTravellers: this.numberOfTravellers.value, departureDate: this.datepipe.transform(fullDate, 'dd-MM-yyyy HH:mm:ss')
        }
      });
  }

  getFile(name) {

    let pictureString = environment.baseUrl + 'file/' + name;

    return pictureString;
  }

  get from() {
    return this.searchFilters.get('from') as FormControl;
  }
  get to() {
    return this.searchFilters.get('to') as FormControl;
  }

  get departureDate() {
    return this.searchFilters.get('departureDate') as FormControl;
  }
  get departureTime() {
    return this.searchFilters.get('departureTime') as FormControl;
  }
  get numberOfTravellers() {
    return this.searchFilters.get('numberOfTravellers') as FormControl;
  }
}
