<section class="latest-news pt-115 pb-115">
    <div class="container">
        <div class="row align-items-center">
            <!-- <div class="col-lg-6 col-md-8 col-sm-7"> -->
            <div class="section-title m-auto">
                <!-- <span class="title-tag">Blog</span> -->
                <h2>Upcoming Shared Flight</h2>
            </div>
            <!-- </div>
            <div class="col-lg-6 col-md-4 col-sm-5 d-none d-sm-block">
                <div class="latest-post-arrow arrow-style text-right">
                    <div class="slick-arrow prev-arrow"><i class="fal fa-arrow-left"></i></div>
                    <div class="slick-arrow next-arrow"><i class="fal fa-arrow-right"></i></div>
                </div>
            </div> -->
        </div>
        <div class="row">
            <!-- Latest post loop -->
            <div class="latest-post-arrow arrow-style text-right " style="align-items: center;">
                <div class="slick-arrow prev-arrow"><i class="fal fa-arrow-left " ></i></div>
            </div>
            <ngx-slick-carousel class="col-lg-10 latest-post-slider mt-80" #slickModal="slick-carousel"
                [config]="blogConfig">

                <div ngxSlickItem class="col-lg-12 cursor" *ngFor='let item of blogpost'
                    (click)="navigateToFlightDetails(item.id)">
                    <div class="latest-post-box">
                        <div class="post-img " style="background-image: url({{item.arrivalAirport.image}});"></div>
                        <div class="post-desc">
                            <div class="post-meta">
                                <div class="row justify-content-between ">
                                    <span class="Roboto" style="  color: #202224;     ">
                                        {{item.departureAirport.cityName}}</span>
                                    <span>
                                        <img src="assets/img/Upcoming-Shared-Flight/Component1.png" alt="">
                                    </span>
                                    <span class="Roboto">{{item.arrivalAirport.cityName}}</span>


                                    <div class="sf " style="color: #0B256E ; ">${{item.price}}/<span
                                            style="font-size:12px">Seat</span></div>
                                </div>
                                <div class="row justify-content-between mt-3">
                                    <span class="Roboto" style="  color: #202224;     ">
                                        {{item.arrivalDate}}</span>


                                    <span class="Roboto" style="  color: #202224;     ">
                                    </span>

                                    <div class="sf " style="color: #0B256E ; ">{{item.remainingSeats}} Seats
                                        Left</div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </ngx-slick-carousel>
            <div class="latest-post-arrow arrow-style text-right" style="align-items: center;">
                <div class="slick-arrow next-arrow"><i class="fal fa-arrow-right"></i></div>
            </div>
        </div>
    </div>
</section>