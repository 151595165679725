import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FlightsService } from 'src/app/core/services/flights.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-upcoming-flights',
  templateUrl: './upcoming-flights.component.html',
  styleUrls: ['./upcoming-flights.component.css']
})
export class UpcomingFlightsComponent implements OnInit {
 
  // Blog
  blogpost = [
  //  { img: 'assets/img/Upcoming-Shared-Flight/MaskGroup-1.png', title: '13 Seats Left', postdate: '28th Aug 2020', price: '$300/Seat', text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore.' },
  //  { img: 'assets/img/Upcoming-Shared-Flight/MaskGroup-2.png', title: '13 Seats Left', postdate: '28th Aug 2020', price: '$300/Seat', text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore.' },
  //  { img: 'assets/img/Upcoming-Shared-Flight/MaskGroup-3.png', title: '13 Seats Left', postdate: '28th Aug 2020', price: '$300/Seat', text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore.' },
  
 ];
 blogConfig = {
   "slidesToShow": 3,
   "slidesToScroll": 1,
   "fade": false,
   "infinite": true,
   "autoplay": true,
   "autoplaySpeed": 4000,
   "arrows": true,
   "dots": false,
   "prevArrow": '.latest-post-arrow .prev-arrow',
   "nextArrow": '.latest-post-arrow .next-arrow',
   "responsive": [
     {
       "breakpoint": 992,
       "settings": {
         "slidesToShow": 2,
       },
     },
     {
       "breakpoint": 576,
       "settings": {
         "slidesToShow": 1,
       },
     },
   ],
 }
  constructor(private flightService:FlightsService,public datepipe: DatePipe,private router:Router) { }

  ngOnInit(): void {
    this.getPopularDistination()
  }
  getPopularDistination(){
    let date =new Date()
    date.setDate(date.getDate()+1)
    let body={
      "departureFilter": {
        "from": this.datepipe.transform(date, 'dd-MM-yyyy HH:mm:ss'),
        "to": this.datepipe.transform(date.setFullYear(date.getFullYear()+1), 'dd-MM-yyyy HH:mm:ss'),
      },
      "pageSize": 10000,
      "pageNumber": 1
    }
    this.flightService.searchFlights(body).subscribe(
      data=>{
        this.blogpost=data['data'].data
        // console.log(this.blogpost);
        
        this.blogpost.forEach(
          e=>{
            // console.log( e.image);
            e.arrivalDate=e.arrivalDate.split(' ')[0]
            e.arrivalAirport.image=this.getFile(e.arrivalAirport.image)

          }
        )
      }
    )
  }
  getFile(name) {
    let pictureString = environment.baseUrl + 'file/' + name;
    return pictureString;
  }
  navigateToFlightDetails(id){
    this.router.navigate( ['flight-details'], { queryParams: { id: id}});
  }
}
