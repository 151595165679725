
<breadcrumb #parent>
    <section class="breadcrumb-area" style="background-image: url('{{ breadcrumbimg }}');">
        <div class="container">
            <div class="breadcrumb-text">
                <ng-template ngFor let-route [ngForOf]="parent.breadcrumbs">
                    <h2 class="page-title">{{ route.displayName }}</h2>
                    <ul class="breadcrumb-nav">
                        <li><a routerLink="/">Home</a></li>
                        <li *ngIf="!route.terminal" class="breadcrumb-item">
                            <a href="" [routerLink]="[route.url]">{{ route.displayName }}</a>
                        </li>
                        <li *ngIf="route.terminal" class="active" aria-current="page">{{ route.displayName }}</li>
                    </ul>
                </ng-template>
            </div>
        </div>
    </section>
</breadcrumb>