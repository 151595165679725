<app-headerfour></app-headerfour>
<app-breadcrumb></app-breadcrumb>
<!--====== ROOM-LIST START ======-->
<section class="room-section room-list-style pt-120 pb-120">
    <div class="container">
        <div class="room-list-loop">
            <div class="room-box mb-30" *ngFor='let item of appartmentlistpost'>
                <div class="row no-gutters justify-content-center">
                    <div class="col-lg-5 col-md-10">
                        <div class="room-img-wrap">
                            <div class="room-img" style="background-image: url({{item.img}});"></div>
                        </div>
                    </div>
                    <div class="col-lg-7 col-md-10">
                        <div class="room-desc">
                            <div class="row align-items-center">
                                <div class="col-sm-8">
                                    <ul class="icons">
                                        <li><i class="fal fa-bed"></i></li>
                                        <li><i class="fal fa-wifi"></i></li>
                                        <li><i class="fal fa-car"></i></li>
                                        <li><i class="fal fa-coffee"></i></li>
                                        <li><i class="fal fa-concierge-bell"></i></li>
                                        <li><i class="fal fa-compress-arrows-alt"></i></li>
                                        <li><i class="fal fa-swimmer"></i></li>
                                    </ul>
                                    <h4 class="title"><a routerLink="/apartment-details">{{item.title}}</a></h4>
                                    <p>
                                        {{item.text}}
                                    </p>
                                </div>
                                <div class="col-sm-4">
                                    <div class="text-sm-center">
                                        <span class="price">${{item.price}}<span>/{{item.period}}</span> </span>
                                        <a routerLink="/apartment-details" class="book-btn">Booking Now</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="pagination-wrap">
            <app-pagination></app-pagination>
        </div>
    </div>
</section>
<!--====== ROOM-LIST END ======-->
<app-footerinner></app-footerinner>