<header class="header-absolute header-two sticky-header" #nav id="navtwo"  >
    <div class="container container-custom-one">
        <div class="nav-container d-flex align-items-center justify-content-around">  
             <!-- Site Logo -->
            <div class="site-logo">
                <a routerLink='/home' class="main-logo"><img width="150px" height="60px" src="assets/img/Header-logo-white.png" alt="Logo"></a>
                <a routerLink='/home' class="sticky-logo"><img width="150px" height="60px" src="assets/img/logo-3.png" alt="Logo"></a>
            </div>
            <!-- Main Menu -->
            <div class="nav-menu d-lg-flex align-items-center">

                <!-- Navbar Close Icon -->
                <div class="navbar-close">
                    <div class="cross-wrap"><span class="top"></span><span class="bottom"></span></div>
                </div>

                <!-- Off canvas Menu  -->
                <div class="toggle">
                    <!-- <a routerLink="#" id="offCanvasBtn" (click)="offCanvasBtn()"><i class="fal fa-bars"></i></a> -->
                </div>
                <!-- Mneu Items -->
                <div class="menu-items">
                    <ul>
                        <li>
                            <a routerLink="/home" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">{{'home'|translate}}</a>
                           
                        </li>

                        <li><a routerLink="/flights" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">{{'flights'|translate}}</a>
                           
                        <li>
                            <a routerLink='/AboutUs' routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">{{'about'|translate}}</a>
                            
                        </li>
                        <li>
                            <a routerLink='/ContactUs' routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">{{'contact'|translate}}</a>
                            
                        </li>

                    </ul>
                </div>

                <!-- from pushed-item -->
                <div class="nav-pushed-item"></div>
            </div>

         

            <!-- Header Info Pussed To Menu Wrap -->
         

            <!-- Navbar Toggler -->
            <div class="navbar-toggler">
                <span></span><span></span><span></span>
            </div>
        </div>
    </div>
</header>
<div class="offcanvas-wrapper" [ngClass]="opencanvas ? '' : 'show-offcanvas'">
    <div class="offcanvas-overly" [ngClass]="opencanvas ? '' : 'show-overly'" (click)="offCanvasclose()"></div>
    <div class="offcanvas-widget">
        <a href="#" class="offcanvas-close" (click)="offCanvasclose()"><i class="fal fa-times"></i></a>
        <app-canvas></app-canvas>
    </div>
</div>