import { Component, OnInit } from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {
  // Video
  videobg = 'assets/img/About-Us/plane.png';
// Map


  constructor() { }

  ngOnInit(): void {
 
  }

}
