import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Flight } from 'src/app/core/models/flight';
import { FlightsService } from 'src/app/core/services/flights.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-apartmentgrid',
  templateUrl: './apartmentgrid.component.html',
  styleUrls: ['./apartmentgrid.component.css']
})
export class ApartmentgridComponent implements OnInit {
  @Input() valueChange: Flight[]
  pageCount: number
  totalRows: number
  pageSize: number = 6;
  pageNumber: number = 1;

  constructor(private flightService: FlightsService, private router: Router, private route: ActivatedRoute) { }
  icons = [
    { icon: 'assets/img/aminities-icons/01.png' }, { icon: 'assets/img/aminities-icons/02.png' },
    { icon: 'assets/img/aminities-icons/03.png' }, { icon: 'assets/img/aminities-icons/04.png' },
    { icon: 'assets/img/aminities-icons/05.png' }, { icon: 'assets/img/aminities-icons/06.png' }
  ]
  appartmentgridpost: Flight[] = [

  ];
  parms
  ngOnInit(): void {
    this.route.queryParams.subscribe(
      params => {
        this.parms = params
      })
    this.search(this.parms)
  }
  getFile(name) {
    let pictureString = environment.baseUrl + 'file/' + name;
    return pictureString;
  }
  navigateToFlightDetails(id) {
    this.router.navigate(['flight-details'], { queryParams: { id: id } });
  }
  search(param?) {

    let body
    if (param!= undefined) {
      // if (param.length != undefined) {
        body = {
          "departureFilter": {
            "from": param.departureDate,
            "to": param.departureDate
          },
          "departureAirportId": Number.parseInt(param.from),
          "arrivalAirportId": Number.parseInt(param.to),
          "pageSize": this.pageSize,
          "pageNumber": this.pageNumber
        }
      
    }
    else {

      body = {
        "pageSize": this.pageSize,
        "pageNumber": this.pageNumber
      }
    }


    this.flightService.searchFlights(body).subscribe(
      data => {

        this.appartmentgridpost = <Flight[]>data.data.data



        this.pageCount = data.data.totalRows / this.pageSize
        if (this.appartmentgridpost != null)
          this.appartmentgridpost.forEach(e => {
            e.arrivalTime = (e.arrivalDate.split(' '))[1]
            e.departureTime = (e.departureDate.split(' '))[1]
            e.image = this.getFile(e.arrivalAirport.image)
            if (e.amenities != null)
              e.amenities.forEach(element => {
                element.icon = this.getFile(element.icon)
                e.amenities= e.amenities.slice(0,6)
              });
          }

          )


      }
    )
  }

  onPageChanged(event: any) {

    if (event) {
      this.pageNumber = event;
      this.search(this.parms)

    }
  }
}
