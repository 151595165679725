import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css']
})
export class BreadcrumbComponent implements OnInit {
@ViewChild('parent',{static:true}) parent
  constructor() { }
  // Image
  breadcrumbimg = 'assets/img/bg/06.png';

  ngOnInit(): void {

    switch (this.parent.breadcrumbs[0].displayName){
    case "Contact Us": {
      this.breadcrumbimg='assets/img/bg/01.png'
      
      break;
    }
    case "Flight Result": {
      this.breadcrumbimg='assets/img/bg/02.png'
      
      break;
    }
    case "Flight Details": {
      this.breadcrumbimg='assets/img/bg/03.png'
      break;
    }
    case "About Us": {
      this.breadcrumbimg='assets/img/bg/04.png'
      break;
    }
    default:{
      
      this.breadcrumbimg='assets/img/bg/04.png'
      break;
    }
    }

  }

}
