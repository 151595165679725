<header class="header-absolute sticky-header" id="header">
    <div class="container container-custom-one">
        <div class="nav-container d-flex align-items-center justify-content-between">
            <!-- Main Menu -->
            <div class="nav-menu d-lg-flex align-items-center">

                <!-- Navbar Close Icon -->
                <div class="navbar-close">
                    <!-- <div class="cross-wrap"><span class="top"></span><span class="bottom"></span></div> -->
                </div>

                <!-- Off canvas Menu  -->
                <div class="toggle">
                    <!-- <a routerLink="#" id="offCanvasBtn" (click)="offCanvasBtn()"><i class="fal fa-bars"></i></a> -->
                </div>
                <!-- Mneu Items -->
                <div class="menu-items">
                    <ul>
                        <li>
                            <a routerLink="/home" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">{{'home'|translate}}</a>
                           
                        </li>

                        <li><a routerLink="/flights" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">{{'flights'|translate}}</a>
                           
                        <li>
                            <a routerLink='/AboutUs' routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">{{'about'|translate}}</a>
                            
                        </li>

                    </ul>
                </div>

                <!-- from pushed-item -->
                <div class="nav-pushed-item"></div>
            </div>

            <!-- Site Logo -->
            <div class="site-logo">
                <a routerLink='/'><img src="assets/img/logo-3.png" alt="Logo"></a>
            </div>

            <!-- Header Info Pussed To Menu Wrap -->
            <div class="nav-push-item">
                <!-- Header Info -->
                <div class="header-info d-lg-flex align-items-center">
                    <div class="item">
                        <i class="fal fa-phone"></i>
                        <span>Phone Number</span>
                        <a routerLink="tel:+90898787709">
                            <h5 class="title">+908 987 877 09</h5>
                        </a>
                    </div>
                    <div class="item">
                        <i class="fal fa-envelope"></i>
                        <span>Email Address</span>
                        <a routerLink="mailto:info@webmail.com">
                            <h5 class="title">info@webmail.com</h5>
                        </a>
                    </div>
                </div>
            </div>

            <!-- Navbar Toggler -->
            <div class="navbar-toggler">
                <span></span><span></span><span></span>
            </div>
        </div>
    </div>
</header>
<div class="offcanvas-wrapper" [ngClass]="opencanvas ? '' : 'show-offcanvas'">
    <div class="offcanvas-overly" [ngClass]="opencanvas ? '' : 'show-overly'" (click)="offCanvasclose()"></div>
    <div class="offcanvas-widget">
        <a href="#" class="offcanvas-close" (click)="offCanvasclose()"><i class="fal fa-times"></i></a>
        <app-canvas></app-canvas>
    </div>
</div>