import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BreadcrumbModule } from 'angular-crumbs';
import { NgwWowModule } from 'ngx-wow';
import { NiceSelectModule } from "ng-nice-select";
import { CountUpModule } from 'countup.js-angular2';
// import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/pages/home/home.component';
import { HometwoComponent } from './components/pages/hometwo/hometwo.component';
import { HomethreeComponent } from './components/pages/homethree/homethree.component';
import { AboutComponent } from './components/pages/about/about.component';
import { ApartmentdetailsComponent } from './components/pages/apartmentdetails/apartmentdetails.component';
import { ApartmentgridComponent } from './components/pages/apartmentgrid/apartmentgrid.component';
import { ApartmentlistComponent } from './components/pages/apartmentlist/apartmentlist.component';
import { BrokerComponent } from './components/pages/broker/broker.component';
import { ClassificationComponent } from './components/pages/classification/classification.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { GalleryComponent } from './components/pages/gallery/gallery.component';
import { NewsComponent } from './components/pages/news/news.component';
import { NewsdetailComponent } from './components/pages/newsdetail/newsdetail.component';
import { PlacesComponent } from './components/pages/places/places.component';
import { PlacesdetailComponent } from './components/pages/placesdetail/placesdetail.component';
import { TrendingComponent } from './components/pages/trending/trending.component';
import { HeaderComponent } from './components/layouts/header/header.component';
import { HeadertwoComponent } from './components/layouts/headertwo/headertwo.component';
import { HeaderthreeComponent } from './components/layouts/headerthree/headerthree.component';
import { HeaderfourComponent } from './components/layouts/headerfour/headerfour.component';
import { PreloaderComponent } from './components/layouts/preloader/preloader.component';
import { FooterComponent } from './components/layouts/footer/footer.component';
import { FootertwoComponent } from './components/layouts/footertwo/footertwo.component';
import { FooterthreeComponent } from './components/layouts/footerthree/footerthree.component';
import { FooterinnerComponent } from './components/layouts/footerinner/footerinner.component';
import { BlogsidebarComponent } from './components/layouts/blogsidebar/blogsidebar.component';
import { BreadcrumbComponent } from './components/layouts/breadcrumb/breadcrumb.component';
import { CanvasComponent } from './components/layouts/canvas/canvas.component';
import { BacktotopComponent } from './components/layouts/backtotop/backtotop.component';
import { BlogpostComponent } from './components/layouts/blogpost/blogpost.component';
import { InstapostComponent } from './components/layouts/instapost/instapost.component';
import { HaboutComponent } from './components/layouts/habout/habout.component';
import { PaginationComponent } from './components/layouts/pagination/pagination.component';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpLoaderFactory } from './common/HttpLoaderFactory';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DownloadTheAppComponent } from './components/layouts/download-the-app/download-the-app.component';
import { AboutUsComponent } from './components/pages/about-us/about-us.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material/input';
import {MatNativeDateModule} from '@angular/material/core';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { UpcomingFlightsComponent } from './components/layouts/upcoming-flights/upcoming-flights.component'
import { DatePipe } from '@angular/common';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { MatSnackBarModule } from '@angular/material/snack-bar';
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HometwoComponent,
    HomethreeComponent,
    AboutComponent,
    ApartmentdetailsComponent,
    ApartmentgridComponent,
    ApartmentlistComponent,
    BrokerComponent,
    ClassificationComponent,
    ContactComponent,
    GalleryComponent,
    NewsComponent,
    NewsdetailComponent,
    PlacesComponent,
    PlacesdetailComponent,
    TrendingComponent,
    HeaderComponent,
    HeadertwoComponent,
    HeaderthreeComponent,
    HeaderfourComponent,
    PreloaderComponent,
    FooterComponent,
    FootertwoComponent,
    FooterthreeComponent,
    FooterinnerComponent,
    BlogsidebarComponent,
    BreadcrumbComponent,
    CanvasComponent,
    BacktotopComponent,
    BlogpostComponent,
    InstapostComponent,
    HaboutComponent,
    PaginationComponent,
    DownloadTheAppComponent,
    AboutUsComponent,
    UpcomingFlightsComponent,

  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SlickCarouselModule,
    NiceSelectModule,
    BreadcrumbModule,
    NgwWowModule,
    CountUpModule,
    NgbModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgxMaterialTimepickerModule,
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    MatSnackBarModule,
  ],
  providers:[DatePipe ]  ,
  bootstrap: [AppComponent]
})
export class AppModule {

}
