import { Component, Input, OnInit, Output,EventEmitter, OnChanges } from '@angular/core';
import { PipeTransform, Pipe } from '@angular/core';
import { Flight } from 'src/app/core/models/flight';
import { FlightsService } from 'src/app/core/services/flights.service';


@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnInit {

  @Input() pageCount: number
  @Output() valueChange = new EventEmitter();
  pages: number[] = []
  constructor(private flightService: FlightsService) { }

  ngOnInit(): void {
    
    this.pageCount = Math.ceil(this.pageCount)

    for (let index = 0; index < this.pageCount; index++) {
      this.pages.push(index + 1)

    }



  }
  search(i) {
  
  
   
    let pageNumberObject  = {
      pageNumber : i
    };
    this.valueChange.emit(i)

  }
  valueChanged(){
    
  }

}
