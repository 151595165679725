
<app-headertwo></app-headertwo>
<!--====== BANNER PART START ======-->
<ngx-slick-carousel class="banner-area banner-style-two" id="bannerSlider" #slickModal="slick-carousel"
  [config]="bannerConfig">
  <div ngxSlickItem class="single-banner d-flex align-items-center justify-content-center"
    *ngFor='let item of bannerpost'>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <div class="banner-content text-center">
            <h1 class="title" data-animation="fadeInLeft" data-delay=".9s">Luxury Jet
                <br> Flights </h1>
            <ul>
              <li data-animation="fadeInUp wow" data-delay="1.1s">
                <a class="main-btn btn-filled" (click)="gotoDownload()">{{item.urltext1}}</a>
              </li>
              <li data-animation="fadeInUp wow" data-delay="1.3s">
                <a class="main-btn btn-border" routerLink="/AboutUs">{{item.urltext2}}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- banner bg -->
    <div class="banner-bg" style="background-image: url({{item.img}});"></div>
    <div class="banner-overly"></div>
  </div>
</ngx-slick-carousel>
<!--====== BOOKING FORM START ======-->
<div class="booking-form overlapped col-md-8 col-lg-8 col-sm-5 centerlized mx-auto">
    <div class="container">
        <div class="booking-form-inner shadow-sm">
            <form [formGroup]="searchFilters">
                <div class="row align-items-end">
                    <div class="col-lg-4 col-md-6">
                        <div class="inputs-filed mt-30">
                            <label for="arrival-date">Where from?</label>

                            <div class="icon"> <img height="17px" width="17px"
                                    src="assets/img/Check-Availability-Icons/from.png"></div>
                                    <input type="text" placeholder="Select Country" aria-label="Number" matInput
                                    [formControl]="from" [matAutocomplete]="auto"
                                    [ngClass]="( isSubmitted) && from.errors?.required? 'form-control-error':'ng-pristine ng-invalid ng-touched'">
                                <mat-autocomplete id = "from" autoActiveFirstOption #auto="matAutocomplete" >
                                    <mat-option *ngFor="let option of fromAirportObs | async" [value]="option?.cityName">
                                        {{option?.cityName}}
                                    </mat-option>
                                </mat-autocomplete>
                            
                        </div>

                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="inputs-filed mt-30">
                            <label for="departure-date">Where to?</label>
                            <div class="icon"> <img height="17px" width="17px"
                                    src="assets/img/Check-Availability-Icons/dist.png" ></div>
                                    <input type="text" placeholder="Select Country" aria-label="Number" matInput
                                   formControlName="to" [matAutocomplete]="to1" 
                                    [ngClass]="( isSubmitted) && to.errors?.required? 'form-control-error':'ng-pristine ng-invalid ng-touched'">
                                <mat-autocomplete id = "to" autoActiveFirstOption #to1="matAutocomplete"(optionSelected)="onSelectionChange($event)">
                                    <mat-option *ngFor="let option of toAirportObs | async" [value]="option?.cityName">
                                        {{option?.cityName}}
                                    </mat-option>
                                </mat-autocomplete>

                        </div>
                    </div> <div class="col-lg-4 col-md-6">
                        <div class="inputs-filed mt-30">
                            <label for="guests">Select date</label>
                            <a class="icon" (click)="picker.open()"><img height="17px" width="17px"
                                    src="assets/img/Check-Availability-Icons/calender.png">
                            </a>
                            <input matInput [matDatepicker]="picker" placeholder="dd/mm/yyyy"
                                [ngClass]="( isSubmitted) && departureDate.errors?.required? 'form-control-error':'ng-pristine ng-invalid ng-touched'"
                                formControlName="departureDate">
                            <mat-datepicker #picker></mat-datepicker>


                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="inputs-filed mt-30">
                            <label for="guests">Select time</label>
                            <a (click)="picker2.open()" class="icon">
                                <img height="17px" width="17px" src="assets/img/Check-Availability-Icons/clock.png">
                            </a>
                            <input [ngxTimepicker]="picker2" placeholder="HH:MM" formControlName="departureTime"
                                [format]="24"
                                [ngClass]="( isSubmitted) && departureTime.errors?.required?  'form-control-error':'ng-pristine ng-invalid ng-touched'">
                            <ngx-material-timepicker #picker2></ngx-material-timepicker>
                        


                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="inputs-filed mt-30">
                            <label for="guests">Number of travellers</label>
                            <div class="icon"> <img height="17px" width="17px"
                                    src="assets/img/Check-Availability-Icons/member.png"></div>
                            <input type="number" placeholder="Number of travellers" formControlName="numberOfTravellers"
                                name="numberOfTravellers" min="0"
                                [ngClass]="( isSubmitted) && numberOfTravellers.errors?.required?  'form-control-error':'ng-pristine ng-invalid ng-touched'">

                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="inputs-filed mt-30">
                            <button type="submit" (click)="search()">check availability</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<!--====== BOOKING FORM END ======-->



<!--====== ABOUT PART START ======-->
<app-habout></app-habout>
<!--====== ABOUT PART END ======-->
<!-- upcoming -->
<app-upcoming-flights></app-upcoming-flights>
<!--====== CORE FEATURES START ======-->
<section class="core-feature-section bg-white pt-115 ">
    <div class="container">
        <div class="section-title text-center mb-50">
            <h2>Core Features</h2>
        </div>
        <!-- Featre Loop -->
        <div class="row features-loop">
            <div class="col-lg-4 col-sm-6 order-1">
                <div class="feature-box fadeInLeft wow  " data-wow-delay=".3s">
                    <div class="icon">
                        <img src="assets/img/Features-Icons/01.png">
                    </div>
                    <h3>LUXURY AND COMFORT</h3>
                    <p class="sf">
                        Unlike the crowded seating you find with commercial airlines, private jets are comfortable and
                        luxurious. Your aircraft seat will be large and comfortable, allowing you to stretch and relax
                        during flight.
                    </p>
                    <span class="count">01</span>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 order-2">
                <div class="feature-box fadeInDown wow " data-wow-delay=".4s">
                    <div class="icon">
                        <img src="assets/img/Features-Icons/02.png">
                    </div>
                    <h3>SAFETY AND CONFIDENTIALITY</h3>
                    <p class="sf">
                        FLYUSVIP places the safety, security and confidentiality of customers and staff above all else
                        and offers the highest global standard for business aviation operators. We hold the highest
                        safety ratings.
                    </p>
                    <span class="count">02</span>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 order-3 order-sm-4 order-lg-3">
                <div class="feature-box fadeInRight wow " data-wow-delay=".5s">
                    <div class="icon">
                        <img src="assets/img/Features-Icons/03.png">
                    </div>
                    <h3>PERSONAL SCHEDULE</h3>
                    <p class="sf">
                        Take-off and landing times, unlike any commercial flight, can be rescheduled at short notice to
                        suit unexpected meetings or accommodate possible changes of plan. Flexibility for passengers is
                        maximal.
                    </p>
                    <span class="count">03</span>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 order-4 order-sm-3 order-lg-4">
                <div class="feature-box fadeInLeft wow  " data-wow-delay=".6s">
                    <div class="icon">
                        <img src="assets/img/Features-Icons/04.png">
                    </div>
                    <h3><a routerLink="#"></a>MANY AIRPORTS</h3>
                    <p class="sf">
                        Compared to commercial flights, private jets can access a higher number of airports, many of
                        which reside in remote areas which would otherwise be left disconnected.
                    </p>
                    <span class="count">04</span>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 order-5">
                <div class="feature-box fadeInUp wow " data-wow-delay=".7s">
                    <div class="icon">
                        <img src="assets/img/Features-Icons/05.png">
                    </div>
                    <h3>PAYMENT OPTIONS</h3>
                    <p class="sf">
                        Users of FlyUsVIP app can make safe payments through the app no cash necessary. There are no
                        hidden booking or other administration surcharges added to the total.
                    </p>
                    <span class="count">05</span>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 order-6">
                <div class="feature-box fadeInRight wow " data-wow-delay=".8s">
                    <div class="icon">
                        <img src="assets/img/Features-Icons/06.png">
                    </div>
                    <h3>SPECIAL OFFERS</h3>
                    <p class="sf">
                        Stay Tuned for our flights offers, promotions, new coming destinations and more. We will notify
                        you when we have special offers.
                    </p>
                    <span class="count">06</span>
                </div>
            </div>
        </div>
    </div>
</section>
<!--====== CORE FEATURES END ======-->


<!--====== LATEST NEWS START ======-->
<app-blogpost></app-blogpost>
<!--====== LATEST NEWS END ======-->


<!--====== download ======-->


<app-download-the-app id="downloadapp"></app-download-the-app>
<!--====== download ======-->
<!-- <app-contact></app-contact> -->