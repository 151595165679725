import { Component, OnInit } from '@angular/core';
import { NgwWowService } from 'ngx-wow';
import * as mapboxgl from 'mapbox-gl';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FlightsService } from 'src/app/core/services/flights.service';
import { MatSnackBar } from "@angular/material/snack-bar";
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  isSubmitted: boolean = false
  contactUs = new FormGroup({
    FullName: new FormControl('', Validators.required),
    MobileNumber: new FormControl('',[ Validators.required,Validators.pattern("[0]{1}[0-9]{9}")]),
    Subject: new FormControl('', [Validators.required]),
    Message: new FormControl('', Validators.required)
  })
  constructor(private wowService: NgwWowService, private flightService: FlightsService,
    private snackBar: MatSnackBar,
  ) {
    this.wowService.init();
  }

  ngOnInit(): void {

  }
  send() {

    const formData = new FormData();
    this.isSubmitted = true
    if (this.contactUs.invalid) {
      console.log("hjgj");
      
      return;
    }


    formData.append("FullName", this.FullName.value)
    formData.append("MobileNumber", this.MobileNumber.value)
    formData.append("Subject", this.Subject.value)
    formData.append("Message", this.Message.value)

    this.flightService.contactUs(formData).subscribe(
      data => {
      this.snackBar.open(
          'Message Sent Successfully',
          '',
          {
            duration: 5000
          }
        );
        this.contactUs.reset()
      })



  

}

get FullName() {
  return this.contactUs.get('FullName') as FormControl;
}
get MobileNumber() {
  return this.contactUs.get('MobileNumber') as FormControl;
}
get Subject() {
  return this.contactUs.get('Subject') as FormControl;
}
get Message() {
  return this.contactUs.get('Message') as FormControl;
}


}
