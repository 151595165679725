<app-headerthree></app-headerthree>
<app-breadcrumb></app-breadcrumb>
<!--====== COUNTER UP START ======-->
<section class="counter-section " style="
position: relative;
top: -30;
top: 110px;
">
    <img src="assets/img/About-Us/sheader.png" alt="">
</section>
<!--====== COUNTER UP END ======-->
<!--====== TEXT BLOCK START ======-->

<section class="text-block bg-black with-pattern pt-115" style="position: unset;">
    <div class="container">
        <div class="row align-items-center justify-content-center">
            <div class="col-lg-6 col-md-10 order-2 order-lg-1">
                <div class="block-text">
                    <div class="section-title mb-20">
                       
                        <h2>The Uber of private flight.</h2>
                    </div>
                    <p class="pr-50">
                        Jet travel has always been notoriously expensive, but through apps like FlyUsVIP, private air travel is becoming more accessible. FlyUsVIP app or you can call it “the Uber of private flight” is an online platform allows travelers to book seats on shared or private flights to destinations across the globe for a fraction of the traditional price. 
                        FlyUsVIP is innovative, flexible and reliable – and we are available 24/7.
                        
                    </p>
                    <!-- <a routerLink="#" class="main-btn btn-filled mt-40"> Book Now</a> -->
                </div>
            </div>
            <div class="col-lg-6 col-md-10 order-1 order-lg-2  fadeInRight" data-wow-delay=".3s">
                <div class="video-wrap video-wrap-two mb-small" style="background-image: url({{videobg}});">
                    <!-- <a href="http://www.youtube.com/embed/watch?v=EEJFMdfraVY" class="popup-video"><i class="fas fa-play"></i></a> -->
                </div>
            </div>
        </div>
    </div>
    
</section>
<!--====== TEXT BLOCK END ======-->

