import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LanguageService } from './language.service';
import { environment } from "../../.././environments/environment"
@Injectable({
  providedIn: 'root'
})
export class FlightsService {

  constructor(
    private http: HttpClient,
    private languageService: LanguageService
) {}
searchFlights(reqBody) {
  
  let api = environment.baseUrl + "flight/search";

  let headers = new HttpHeaders(
      this.languageService.isRtl()
          ? { "Accept-Language": "ar-SA" }
          : { "Accept-Language": "en-GB" }
  );
  return this.http.post<any>(api, reqBody, { headers: headers });
}
getFlightbyid(id) {

  let api=environment.baseUrl + "flight/"+id;
  
  return this.http.get(api);
}
getAirports() {
  
  let api=environment.baseUrl + "airport?PageSize=10000000&PageNumber=1"
  
  return this.http.get(api);
}
getroutes() {
  
  let api=environment.baseUrl + "route?PageSize=4&PageNumber=1"
  
  return this.http.get(api);
}
contactUs(reqBody){
   
  let api = environment.baseUrl + "contactUs";

  let headers = new HttpHeaders(
      this.languageService.isRtl()
          ? { "Accept-Language": "ar-SA" }
          : { "Accept-Language": "en-GB" }
  );
  return this.http.post<any>(api, reqBody, { headers: headers });
}


}
