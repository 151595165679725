import { Airport } from "./Airport"
import { Amenity } from "./Amenity"
import { Jet } from "./Jet"

export class Flight {
  image:string
  airline:string
  amenities:Amenity []
  arrivalAirport:Airport
  arrivalDate
  departureAirport : Airport
  departureDate 
  id:number
  jet :Jet
  price:number
  remainingSeats:number
  seats:number
  status:number
  type:number
  arrivalTime
  departureTime
  totalRows:number
  
}
