import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footerinner',
  templateUrl: './footerinner.component.html',
  styleUrls: ['./footerinner.component.css']
})
export class FooterinnerComponent implements OnInit {

  constructor() { }
  logo = 'assets/img/logo-2.png';
  ngOnInit(): void {
  }

}
