import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutUsComponent } from './components/pages/about-us/about-us.component';
import { ApartmentdetailsComponent } from './components/pages/apartmentdetails/apartmentdetails.component';
import { ApartmentgridComponent } from './components/pages/apartmentgrid/apartmentgrid.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { HomeComponent } from './components/pages/home/home.component';
import { HometwoComponent } from './components/pages/hometwo/hometwo.component';
import { TrendingComponent } from './components/pages/trending/trending.component';

const routes: Routes = [ 
  {path:'',component:HomeComponent, data: { breadcrumb: 'Homepage' }},
  {path:'home',component:HomeComponent, data: { breadcrumb: 'Homepage' }},
  {path:'flights',component:ApartmentgridComponent, data: { breadcrumb: 'Flights Result' }},
  {path:'flight-details',component:TrendingComponent, data: { breadcrumb: 'Flight Details' }},
  {path:'AboutUs',component:AboutUsComponent, data: { breadcrumb: 'About Us' }},
  {path:'ContactUs',component:ContactComponent, data: { breadcrumb: 'Contact Us' }}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true }) ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
