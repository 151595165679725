<app-headerthree></app-headerthree>
<app-breadcrumb></app-breadcrumb>
<!--====== ROOM-GRIRD START ======-->

<section class="room-section room-grid-style pt-30 pb-90">
<!-- 
    <div class=" fadeInRight sf col-md-6 col-lg-6 col-sm-4 pl-125 pb-30 "> <span class="pl-80" data-wow-delay="0.5s"> Fly
            anywhere, anytime
            with FLYUS VIP. We built a
            one-stop-shop for everything you need when flying private, while giving you full control to assemble all
            aspects of your journey.
        </span>
    </div> -->
    <div class="container">
        <div *ngIf="appartmentgridpost.length==0"class="row justify-content-center">
           <h1> No Flight Available!</h1> <br>
           <!-- You don’t have flight requests or upcoming flights, initiate a new flight? -->
        </div>
        <div class="row justify-content-center room-gird-loop">
            <div (click)="navigateToFlightDetails(item.id)" class="col-lg-4 col-md-6 col-sm-6 cursor"
                *ngFor='let item of appartmentgridpost'>
                <div class="room-box mb-30">
                    <div class="room-img-wrap">
                        <div class="room-img" style="background-image: url({{item.image}});"></div>
                    </div>
                    <div class="room-desc">
                        <ul class="icons">
                            <li *ngFor="let i of item.amenities"><img height="20px" width="20px" src="{{i.icon}}"
                                    alt=""></li>

                        </ul>
                        <div class="Roboto d-flex justify-content-between">
                            <div>


                                {{item.departureAirport.cityName}}
                                <br>
                                {{item?.departureTime}}
                            </div>
                            <div style="
                            margin: auto;
                        "><img src="assets/img/room/Group2797.png" alt=""></div>
                            <div> {{item.arrivalAirport.cityName}}
                                <br>
                                {{item?.arrivalTime}}
                            </div>
                        </div>
                        <hr>
                        <div class="Roboto d-flex justify-content-between">
                            <div> {{item.jet.model}}
                                <br>
                                {{item.departureAirport.name}}
                            </div>

                            <div> {{item.remainingSeats}} Seats Left
                                <br>
                                ${{item.price}} /Seat
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
        <div class="pagination-wrap">
            <app-pagination   *ngIf = "pageCount" [pageCount]="pageCount"
            (valueChange) = "onPageChanged($event)"></app-pagination>
        </div>
    </div>
</section>